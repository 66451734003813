import React from 'react'
import BasePage from '../../components/basePage'
import { Button, Text, Container } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { getTempProfile, setTempProfile } from '../../helpers/database'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { object, string } from 'yup'
import Input from '../../components/input'

const NamePage = ({
	basePath = '/login',
	backBtn,
	onClose,
	inVenue,
	closeBtnText,
	fullWidthActionBtns
}) => {
	const tempProfile = getTempProfile()
	const { name, surname, nickname } = tempProfile || {}

	const resolver = yupResolver(
		object({
			name: string().trim().required().min(2),
			surname: string().trim().required().min(2),
			nickname: string()
				.notBadWords()
				.trim()
				.required('Nickname must be set as appears on leaderboards')
				.min(2)
				.matches(/^\S*$/, 'The nickname can not contain spaces')
		}).required()
	)
	const {
		watch,
		register,
		handleSubmit,
		getValues,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onBlur',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			name,
			surname,
			nickname
		}
	})
	const watchName = watch('name')
	const watchSurname = watch('surname')
	const watchNickname = watch('nickname')
	const isValid =
		!!watchName && !!watchSurname && !!watchNickname && !!watchNickname

	const onSubmit = async () => {
		const data = getValues()
		if (!data?.name || !data?.surname || !data?.nickname) return
		const tempProfile = getTempProfile()
		setTempProfile({
			...(tempProfile || {}),
			name: data.name,
			surname: data.surname,
			nickname: data.nickname
		})
		await navigate(`${basePath}/terms/`)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				theme="dark"
				access={1}
				parent="login"
				backBtn={backBtn}
				hideSidebar={inVenue}
				closeBtnText={closeBtnText}
				closeBtn={!!onClose}
				onClose={onClose}
				fullWidthActionBtns={fullWidthActionBtns}
				largeTitle={
					inVenue ? (
						<>
							<Text color="white" as="h1" textAlign="center">
								WELCOME TO
							</Text>
							<StaticImage
								width={380}
								alt="Logo"
								style={{ marginBottom: 80 }}
								src="../../images/logo-white.png"
							/>
							<br />
							What’s
							<br />
							your name?
						</>
					) : (
						<>
							What’s
							<br />
							your name?
						</>
					)
				}
				primaryBtn={
					<Container maxW="md" centerContent>
						<Button
							style={{ width: '100%' }}
							variant="primary"
							disabled={!isValid}
							type="submit"
						>
							Continue
						</Button>
					</Container>
				}
			>
				<Container maxW="md">
					<Input
						name="name"
						type="text"
						variant="filled"
						placeholder="Your first name"
						mt="40px"
						register={register}
						errors={errors}
					/>
					<Input
						name="surname"
						type="text"
						variant="filled"
						placeholder="Your last name"
						mt="20px"
						register={register}
						errors={errors}
					/>
					<Input
						name="nickname"
						type="text"
						variant="filled"
						placeholder="Your nickname"
						mt="20px"
						register={register}
						errors={errors}
					/>
					<Text
						variant="m"
						color="white"
						textAlign="center"
						mt="10px"
					>
						Tip: Nicknames appear on the public leaderboard. Choose
						wisely!
					</Text>
				</Container>
			</BasePage>
		</form>
	)
}

export default NamePage
