import * as React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import Dashboard from './pages/dashboard'

const Base = ({ parent, theme, children, hideSidebar }) => (
	<Flex>
		<Flex
			flex="1"
			ml={{ lg: '0', base: '0' }}
			justifyContent="center"
			bg={{ lg: 'black', base: theme }}
		>
			<Box w="100%" maxW={{ md: '400px', base: '100vw' }}>
				{children}
			</Box>
		</Flex>
	</Flex>
)

export default Base
