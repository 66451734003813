import { getRedirectUrl, setBookingId, setRedirectUrl } from './database'

const isBaseRoute = (url) => {
	const path = (url || '').replace(/\//g, '')
	const baseRoutes = [
		'/',
		'groups',
		'leaderboards',
		'games',
		'food',
		'profile'
	]
	return baseRoutes.includes(path)
}

const isPublicFlow = (path) => {
	const routes = [
		'/start/',
		'/404/',
		'/test-scores/',
		'/venue-tv/',
		'/public-leaderboard/'
	]
	return (
		routes.includes(path) ||
		routes.includes(`${path}/`) ||
		routes.includes(`/${path}`)
	)
}

const isLoginFlow = (path) => {
	const routes = ['/login/', '/login/not-valid/', '/in-venue-onboarding']
	return routes.includes(path) || routes.includes(`${path}/`)
}

const isAccountCreationFlow = (path) => {
	const routes = [
		'/login/name/',
		'/login/birthdate/',
		'/login/terms/',
		'/in-venue-onboarding/terms/',
		'/in-venue-onboarding/name/'
	]
	return routes.includes(path) || routes.includes(`${path}/`)
}

const checkAccess = (isLoggedIn, missingRequired) => {
	const path = typeof window !== 'undefined' ? window.location.pathname : ''
	const isPublicPage = isPublicFlow(path)
	const isLoginPage = isLoginFlow(path)
	const isAccountCreationPage = isAccountCreationFlow(path)
	const isPrivatePage =
		!isPublicPage && !isLoginPage && !isAccountCreationPage

	const params =
		typeof window !== 'undefined'
			? new URLSearchParams(window.location.search)
			: null

	const isWalkIn = params?.get('walkIn') || null
	const eventId = params?.get('eventId')?.toString() || null

	if (isPublicPage) {
		return null
	}

	if (path.includes('in-venue-onboarding')) {
		return null
	}

	if (isLoggedIn) {
		if (missingRequired) {
			if (!isAccountCreationPage) {
				// If logged in flow but missing fields
				if (path.includes('/groups/')) {
					setRedirectUrl(path)
				}

				if (path.includes('in-venue-onboarding')) {
					return '/in-venue-onboarding/name'
				}
				return '/login/name/'
			}
		} else {
			if (isWalkIn) {
				// If logged walkIn flow with all required fields
				if (eventId) {
					return `/event-code?eventId=${eventId}`
				} else {
					return '/event-code'
				}
			} else if (!isPrivatePage) {
				// If loggedIn and complete profile
				return '/'
			}
		}
		return null
	} else {
		if (isWalkIn) {
			// If not logged in and walkIn flow
			if (eventId) {
				setRedirectUrl(`/event-code?eventId=${eventId}`)
			} else {
				setRedirectUrl('/event-code')
			}
		} else if (!isLoginPage) {
			// If not logged in and not login flow
			if (path.includes('/groups/') || path.includes('/join-booking/')) {
				setRedirectUrl(path)
			}

			if (path.includes('in-venue-onboarding')) {
				return '/in-venue-onboarding'
			}
		}
		return '/login/'
	}
}

export { isBaseRoute, checkAccess }
