import * as React from 'react'
import { Input as InputChakra, Text, Flex } from '@chakra-ui/react'

const Input = ({ name, register, errors, ...props }) => {
	let extraProps = {}

	if (props.onChange) {
		extraProps.onChange = props.onChange
	}
	return (
		<>
			<InputChakra {...props} {...register(name, extraProps)} />
			{(errors || {})[name] && (
				<Flex justifyContent="center" mb="10px">
					<Text variant="error" as="span">
						{errors[name].message}
					</Text>
				</Flex>
			)}
		</>
	)
}

export default Input
