import * as React from 'react'
import { navigate } from 'gatsby'
import Layout from '../../layout'
import NamePage from '../../pagesComponents/login/name'
import TimeoutModal from '../../components/timeoutModal'

import { useAuth } from '../../hooks/useAuth'

const Name = () => {
	const { resetInVenueOnboarding } = useAuth()
	return (
		<Layout title="Enter your name">
			<NamePage
				basePath="/in-venue-onboarding"
				backBtn={-1}
				inVenue
				closeBtnText="Exit"
				fullWidthActionBtns
				onClose={async () => {
					resetInVenueOnboarding()
				}}
			/>
			<TimeoutModal onReset={resetInVenueOnboarding} />
		</Layout>
	)
}

export default Name
